import Reactб from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import Promo from "../../pages/Promo";
import Images from "../../pages/Images";
import News from "../../pages/News";
import cs from './Content.module.css';

const Content = () => {
    return (
        <div className={cs.content}>
            <Routes>
                <Route path='/news'  element={<News/>}/>
                <Route path='/images'  element={<Images/>}/>
                <Route path='/promo' element={<Promo/> }/>
                <Route path="*" element={<Navigate to="/news" replace/>} />
            </Routes>
        </div>
    );
};

export default Content;