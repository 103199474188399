import React, {useEffect, useState} from 'react';
import cs from './Images.module.css';
import Post from  '../components/Post'

const Images = () => {
    const [data, setData] = useState<string[]>([]);

    useEffect(() => {
        fetch('/api/images')
            .then(response => response.json())
            .then(data => setData(data))
            .catch(error => {
                console.error(error);
            });
    }, []);

    return (
        <div className={cs.images}>
            {
                data.length ?
                    data.map((val, index) => <Post key_={index} url_={val}/>)
                    : <div className={cs.error}></div>
            }
        </div>
    );
};

export default Images;