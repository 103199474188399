import React, {FC, ReactNode} from 'react';
import YouTube, {YouTubeProps} from "react-youtube";
import {YouTubeEmbed, FacebookEmbed, InstagramEmbed, TwitterEmbed, TikTokEmbed} from 'react-social-media-embed';

interface IPost {
    key_: number,
    url_: string
}

const Post: FC<IPost> = ({key_, url_}) => {
    const width = 350;
    const opts: YouTubeProps['opts'] = {
        height: '623',
        width: width,
    }
    const onPlayerReady: YouTubeProps['onReady'] = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    const getId = (url: string) => {
        if (url.includes('?')) {
            const arr = url.split('?');
            arr.pop();
            url = arr[0];
        }

        const arr = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        const youtubeId = undefined !== arr[2] ? arr[2].split(/[^\w-]/i)[0] : arr[0];
        if (youtubeId.includes('https://youtube.com/shorts/')) {
            return youtubeId.replace('https://youtube.com/shorts/', '');
        }
        if (youtubeId.includes('https://www.youtube.com/shorts/')) {
            return youtubeId.replace('https://www.youtube.com/shorts/', '');
        }
        return youtubeId;
    };
    let post:ReactNode = null;

    if (url_.includes("youtube.com/shorts"))
        post = <YouTube key={key_} videoId={getId(url_)} opts={opts} onReady={onPlayerReady}/>;
    else if (url_.includes("youtube"))
        post = <YouTubeEmbed key={key_} url={url_} width={width}/>
    else if (url_.includes("facebook"))
        post = <FacebookEmbed key={key_} url={url_} width={width} />
    else if (url_.includes("instagram"))
        post = <InstagramEmbed key={key_} url={url_} width={width} />;
    else if (url_.includes("twitter"))
        post = <TwitterEmbed key={key_} url={url_} width={width}/>;
    else if (url_.includes("tiktok"))
        post = <TikTokEmbed key={key_} url={url_} width={width}/>;

    return  (<div>{post}</div>);
};

export default Post;