import React from 'react';
import cs from './Header.module.css'
import title from '../../assets/title.png'

const Header = () => {
    return (
        <div className={cs.header}>
            <div className={cs.up}>
                <div className={cs.logo}/>
                <div className={cs.text}>LIVE ORIGAMI STORIES</div>
                <div className={cs.stub}/>
            </div>
            <div>
                <img src={title} className={cs.title_img}/>
            </div>
        </div>
    );
};

export default Header;