import React, {useState, useEffect, RefObject, FC} from 'react';
import Modal from 'react-modal';
import {NavLink, useLocation} from "react-router-dom";
import cs from './Burger.module.css'
import stories from '../../assets/stories.png';
import images from '../../assets/images.png'
import news from '../../assets/news.png'

interface IBurger {
    parent:  RefObject<HTMLDivElement>
}
const Burger:FC<IBurger> =  ({ parent })=> {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const location = useLocation();
    const style = (props: { isActive: boolean }) => {
        return {color: props.isActive ? "#B52323" : "#527BAA"};
    }
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        if (document) {
            document.body.style.overflow = isMenuOpen ? 'hidden' : 'unset'
        }
    }, [isMenuOpen])
    const openMenu = () => {
        setIsMenuOpen(true);
    };
    const closeMenu = () => {
        setIsMenuOpen(() => false);
    };
    const selectIcon = () => {
        switch(location.pathname) {
            case "/images" : return images;
            case "/news" : return news;
            default:
        }
        return stories;
    }

    return (
        <div>
            {windowWidth > 800 ? (
                <div className={cs.links}>
                    <NavLink to="/news" className={cs.link} style={style}>
                        <div className={cs.text}>News</div>
                    </NavLink>
                    <NavLink to="/images" className={cs.link} style={style}>
                        <div className={cs.text}>Images</div>
                    </NavLink>
                    <NavLink to="/promo" className={cs.link} style={style}>
                        <div className={cs.text}>Promo</div>
                    </NavLink>
                </div>
            ) : (windowWidth > 600 ? (
                    <div className={cs.links} style={{cursor: 'pointer'}}>
                        <img className={cs.image} src={selectIcon()} onClick={openMenu} alt={""}/>
                        <NavLink to={location.pathname} className={cs.link} style={style} onClick={openMenu}>
                            <div className={cs.text}>
                                {location.pathname.toUpperCase().at(1) + location.pathname.slice(2)}
                            </div>
                        </NavLink>
                        <Modal isOpen={isMenuOpen} className={cs.modal}
                               style={{
                                   overlay: {
                                       zIndex: 101,
                                   },
                                   content: {

                                   position: "absolute",
                                top: parent?.current?.getBoundingClientRect().top,
                                left:  parent?.current?.getBoundingClientRect().left
                                }}}
                        >
                            <NavLink to="/news" className={cs.link} style={style} onClick={closeMenu}>
                                <div className={cs.text}>News</div>
                            </NavLink>
                            <NavLink to="/images" className={cs.link} style={style} onClick={closeMenu}>
                                <div className={cs.text}>Images</div>
                            </NavLink>
                            <NavLink to="/promo" className={cs.link} style={style} onClick={closeMenu}>
                                <div className={cs.text}>Promo</div>
                            </NavLink>
                        </Modal>
                    </div>
            ) : (
                <div className={cs.links} style={{cursor: 'pointer'}}>
                    <img className={cs.image} src={selectIcon()} onClick={openMenu} alt={""}/>
                    <Modal isOpen={isMenuOpen} className={cs.modal}
                           style={{
                               overlay: {
                                   zIndex: 101,
                               },
                               content: {
                                   position: "absolute",
                                   top: parent?.current?.getBoundingClientRect().top,
                                   left:  parent?.current?.getBoundingClientRect().left
                               }}}
                    >
                        <NavLink to="/news" className={cs.link} style={style} onClick={closeMenu}>
                            <div className={cs.text}>News</div>
                        </NavLink>
                        <NavLink to="/images" className={cs.link} style={style} onClick={closeMenu}>
                            <div className={cs.text}>Images</div>
                        </NavLink>
                        <NavLink to="/promo" className={cs.link} style={style} onClick={closeMenu}>
                            <div className={cs.text}>Promo</div>
                        </NavLink>
                    </Modal>
                </div>
                ) 
            )}
        </div>
    );
};

export default Burger;