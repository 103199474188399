import React from 'react';
import './App.css';
import {BrowserRouter} from "react-router-dom";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Middle from "./components/layout/Middle";
import Modal from "react-modal"

Modal.setAppElement('#root');

function App() {
  return (
      <BrowserRouter>
        <div className="App">
          <Header/>
            <Middle/>
          <Footer/>
        </div>
      </BrowserRouter>
  );
}

export default App;
