import React from 'react';
import cs from './Middle.module.css'
import Content from "./Content";
import Menu from "./Menu"

const Middle = () => {
    return (
        <div className={cs.middle}>
            <Menu/>
            <Content/>
        </div>
    );
};

export default Middle;